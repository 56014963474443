/**
 * hide scrollbars
 */
::-webkit-scrollbar {
  display: none;
  width: 0 !important;
}
.disable-scrollbars {
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE 10+ */
}

#root {
  width: 100%;
  min-width: 100%;
  height: 100%;
  min-height: 100%;
  padding: 0;
  margin: 0;
  overflow-x: hidden;
  position: relative;
}

.link {
  @apply text-blue-500;
}

.shadow-outline-red {
  box-shadow: 0 0 0 3px theme("colors.red.300");
}
.shadow-outline-green {
  box-shadow: 0 0 0 3px theme("colors.green.300");
}
.shadow-outline-white {
  box-shadow: 0 0 0 3px rgba(255, 255, 255, 1);
}
.shadow-outline-black {
  box-shadow: 0 0 0 3px rgba(0, 0, 0, 1);
}

.react-switch {
  vertical-align: middle;
  margin-left: 4px;
}

.react-icons {
  vertical-align: middle;
}
.w-fit{width: fit-content;}
.z-1 {
  z-index: 1;
}
.z-2 {
  z-index: 2;
}
.logo-atom {
  background: url(`${process.env.PUBLIC_URL}/ATOM-Logo.png`) no-repeat 0 0 fixed;
  background-size: cover;
}
.bg-1 {
  background: url(/bg-login-1.jpg) no-repeat 0 0 fixed;
  background-size: cover;
}
.bg-login-2 {
  background: url(`${process.env.PUBLIC_URL}/bg-login-2.png`) no-repeat 0 0 fixed;
  background-size: contain;
}
.bg-login-3 {
  background: url(/bg-login-2.png) no-repeat 0 0 fixed;
  background-size: cover;
}

.w-96 {
  width: calc(theme("spacing.1") * 96);
}
.w-128 {
  width: calc(theme("spacing.1") * 128);
}

/* social media colors */
.text-facebook {
  color: #365397;
}
.text-twitter {
  color: #00a9f1;
}
.text-github {
  color: #2f2f2f;
}

.text-linkedin {
  color: #006db3;
}
.text-apple {
  color: #737373;
}
.text-google {
  color: #4285f4;
}
.text-google-plus {
  color: #e0452c;
}
.text-youtube {
  color: #ff3333;
}
.text-vimeo {
  color: #48b6ed;
}
.text-pinterest {
  color: #ce1a19;
}
.text-yelp {
  color: #c30f00;
}
.text-dribbble {
  color: #ed4584;
}
.text-amazon {
  color: #ff9700;
}
.text-skype {
  color: #00acf4;
}
.text-instagram {
  color: #396d9a;
}
.text-dropbox {
  color: #0d84de;
}
.text-flickr {
  color: #ea0066;
}
.text-tumblr {
  color: #304c68;
}
.text-foursquare {
  color: #207dc5;
}

/* loading circle */
.lds-dual-ring {
  position: absolute;
  right: 2px;
  width: 30px;
  height: 30px;
}
.lds-dual-ring:after {
  background-color: transparent;
  content: " ";
  display: block;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 2px solid #002970;
  border-color: #002970 transparent #002970 transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}
.remove_ic_select{
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  src: local("Montserrat"),
    url(../assets/fonts/montserrat/Montserrat.woff2) format("woff2");
}

@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  src: local("Montserrat"),
    url(../assets/fonts/montserrat/Montserrat-Medium.woff2) format("woff2");
}

@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  src: local("Montserrat"),
    url(../assets/fonts/montserrat/Montserrat-SemiBold.woff2) format("woff2");
}

@font-face {
  font-family: "Montserrat";
  font-style: bold;
  font-weight: 700;
  src: local("Montserrat"),
    url(../assets/fonts/montserrat/Montserrat-Bold.woff2) format("woff2");
}

body {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}