.pagination {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;
}

.pagination__button {
    border: none;
    padding: 0 12px;
    background-color: #FAFAFA;
    color: #002970;
    border-radius: 8px;
    height: 40px;
}

.pagination__item {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #FAFAFA;
    color: #002970;
    width: 40px;
    height: 40px;
    font-size: 16px;
    cursor: pointer;
    border-radius: 8px;

}

.pagination__item.active {
    color: #fff !important;
    background-color: #0598ED !important;
    border-radius: 32px !important;
}