.leaflet-container {
  width: 100%;
  height: 400px;
}
.leaflet-grayscale {
  .leaflet-tile-pane {
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
  }
}

